import './bugsnag';

// Globally define jQuery for use in all components
import $ from 'jquery';

// Bootstrap
import 'bootstrap';

global.$ = $;
global.jQuery = $;
