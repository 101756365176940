import bugsnag from '@bugsnag/js';
import { datadogLogs } from '@datadog/browser-logs';

let client = null;
if (typeof BUGSNAG_API_KEY !== 'undefined' && BUGSNAG_API_KEY) {
    client = bugsnag({
        apiKey: BUGSNAG_API_KEY,
        beforeSend: (report) => {
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has('fbclid')) { // facebook sharing bot
                report.ignore();
            }
        },
    });
}
const bugsnagClient = client;

export default bugsnagClient;

if (typeof DATADOG_API_KEY !== 'undefined' && DATADOG_API_KEY) {
    datadogLogs.init({
        clientToken: DATADOG_API_KEY,
        datacenter: 'eu',
        isCollectingError: true,
        sampleRate: 100,
    });
}
